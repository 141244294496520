import moment from "moment";
import {
  IAutoNumberAttibute,
  IAutoNumberFormat,
  IFormat,
} from "../IRequestModel/IAutoNumberFormat";
import { IMemoDetailModel } from "../IRequestModel/IMemoDetailModel";
import { GetAutoNumber } from "../Services/RequestControlService";
export async function genAutoNum(
  _control: any,
  autoNumFormat: IAutoNumberAttibute,
  template_id: any,
  status: any
) {
  try {
    if (autoNumFormat.formats.length > 0) {
      let autoNumberAttibute: IAutoNumberAttibute = autoNumFormat;
      let str: string[] = [];
      let isCheck: boolean = false;
      let requestBody: any = {};
      let formats: IAutoNumberFormat[] = autoNumberAttibute.formats;
      let choiceFormat = null;
      let tempLabels: string[] = [];
      let tempValues: string[] = [];
      for (let i = 0; i < formats.length; i++) {
        if (formats[i].condition.length > 0) {
          const con = formats[i].condition;
          for (let j = 0; j < con.length; j++) {
            _control.forEach((item: any, rowIdx: number) => {
              item.layout.forEach((layout: any) => {
                if (con[j].label === layout.template.label) {
                  if (con[j].value === layout.data.value) {
                    if (!tempLabels.includes(con[j].label)) {
                      tempLabels = [...tempLabels, con[j].label];
                      tempValues = [...tempValues, con[j].value];
                    }                    
                  }
                }
              });
            });           
          }
        } 
      }

      for (let i = 0; i < formats.length; i++){
        if (formats[i].condition.length > 0){
          const con = formats[i].condition;
          let tempCheck = 0;
          if(tempLabels.length>0)
          {
            if(tempLabels.length==con.length)
            {
              for (let j = 0; j < con.length; j++) {   
                if(tempLabels.includes(con[j].label)&&tempValues.includes(con[j].value)){
                  tempCheck++;
                }
                if(tempCheck!=0){
                  if(tempCheck==con.length){
                    console.log("autoNumFormat_str_formats[i]", formats[i]);
                    choiceFormat = formats[i];
                  }
                }
              }
            }
          }  
        }
        else{
          if(choiceFormat==null){
            console.log("autoNumFormat_str_ccccc", formats[i]);
            choiceFormat = formats[i];
          }
        
        }
      }
    
    
      console.log("autoNumFormat_str_choiceFormat",choiceFormat);
      console.log("autoNumFormat_str_tempLabels",tempLabels);
      console.log("autoNumFormat_str_tempValues",tempValues);
      if (choiceFormat !== null) {
        choiceFormat.format.map((format: any) => {
          if(format.type){
            if (format.type === "pf") {
              if (!str.includes(format.label)) {
                str.push(format.label);
              }
            } else if (format.type === "datetime") {
              const formattedYear = moment().format(format.label);
              str.push(formattedYear);;
          } else if (format.type === "ddl") {
            _control.forEach((item: any, rowIdx: number) => {
              item.layout.forEach(async (layout: any) => {
                if (layout.template.label === format.label) {
                  let value: string = layout.data.value;
                  let newPosition = format.position;
                  if (value !== null) {
                      if (format.condition === "Split") {
                      let newFormat = value.split(format.symbol);
                      console.log("autoNumFormat_str_Split", newFormat);
                      for(let i = 0; i< newFormat.length; i++){
                        newPosition = newPosition.replace(
                          new RegExp("\\$\\{" + (i + 1) + "\\}", "gi"),
                          newFormat[i]
                        );
                      }
                      if (newPosition.indexOf("(") > -1 && newPosition.indexOf(")")) {
                        str.push(
                          newPosition.substring(
                            newPosition.indexOf("(") + 1,
                            newPosition.indexOf(")")
                          )
                        );
                      }
                      else {
                        str.push(newPosition);
                      }                      
                  }
                  if (format.condition === "None" || format.condition == "" || !format.condition) {
                    if (value.indexOf("(") > 0 && value.indexOf(")")) {
                      str.push(
                        value.substring(
                          value.indexOf("(") + 1,
                          value.indexOf(")")
                        )
                      );
                    } else {
                      str.push(value);
                    }
                  }
                    
                  }
                  
                }});
              });
            }
          }
        });
        if (str.length === choiceFormat.format.length) {
          if (
            !str.includes("--select--") &&
            !str.includes("-- select --") &&
            !str.includes("-- please select --") &&
            !str.includes("--- please select ---") &&
            !str.includes("--please select--") &&
            !str.includes("---please select---")&&
            !str.includes("--Select--") &&
            !str.includes("-- Select --") &&
            !str.includes("-- Please Select --") &&
            !str.includes("--- Please Select ---") &&
            !str.includes("--Please Select--") &&
            !str.includes("---Please Select---")
          ) {
            isCheck = true;   
          }
        }
      }
      if (status === "New Request") {
        if (isCheck) {
          const showSymbol = autoNumFormat.showSymbol;
          let prefix = "";
          if (showSymbol) {
            prefix = str.join("-") + "-";
          } else {
            prefix = str.join("");
          }
          console.log("autoNumFormat_str", str);
          autoNumFormat.fisrtPreix = prefix;
          requestBody.Prefix = prefix;
          requestBody.Digit = autoNumFormat.digit;
          requestBody.TemplateId = template_id;
          requestBody.Status = status;
          requestBody.Label =
            _control[autoNumberAttibute.rowIndex].layout[
              autoNumberAttibute.colIndex
            ].template.label;
          const dd = await GetAutoNumber(requestBody);
          if (dd.Message !== undefined) {
          } else {
            _control[autoNumberAttibute.rowIndex].layout[
              autoNumberAttibute.colIndex
            ].data.value = dd;
          }
        } else if (!isCheck) {
          _control[autoNumberAttibute.rowIndex].layout[
            autoNumberAttibute.colIndex
          ].data.value = "";
        }
      }
      return _control;
    }
  } catch (error) {
    console.error("auto=>error", error);
  }
}

export function getValueControl(
  controlID: string,
  memoDetail: IMemoDetailModel
) {
  let strValue: string = "";

  switch (controlID.toLowerCase()) {
    case "requestor id":
      strValue = memoDetail.requestor.EmployeeId.toString();
      break;
    case "requestor code":
      strValue = memoDetail.requestor.EmployeeCode;
      break;
    case "requestor name":
      strValue = memoDetail.requestor.NameEn;
      break;
    case "requestor email":
      strValue = memoDetail.requestor.Email;
      break;
    case "requestor position":
      strValue = memoDetail.requestor.PositionNameEn;
      break;
    case "requestor department":
      strValue = memoDetail.requestor.DepartmentNameEn;
      break;
    default:
      // handle default case here
      break;
  }

  return strValue;
}
